<template>
  <panel :title="title" bordered>
    <b-row>
      <b-col md="12" class="mt-3">
        <data-table-local
          id="table-general-comittee-detail"
          :fields="_fields"
          :items="_items"
          :newable="false"
          :viewable="false"
          :editable="false"
          :deleteable="false"
        />
      </b-col>
    </b-row>
  </panel>
</template>


<script>
export default {
	props: {
		title: String,
		items: null,
	},

	computed: {
		_items() {
			if (this.items.listOfGuaranteeToLoanData) {
				return this.items.listOfGuaranteeToLoanData.map((item) => {
					const {
						amount: loan_collateral_amount,
						name: loan_collateral_name,
						loan_data: {
							balance_amount,
							loan_topic: { name: loan_topic_name },
						},
					} = item
					return {
						loan_collateral_amount: this.withCommas(loan_collateral_amount),
						loan_collateral_name,
						balance_amount: this.withCommas(balance_amount),
						loan_topic_name,
					}
				})
			} else {
				return []
			}
		},
		_fields() {
			return {
				balance_amount: {
					label: 'หนี้คงเหลือ',
				},
				loan_collateral_amount: {
					label: 'ยอดค้ำคงเหลือ',
				},
				loan_collateral_name: {
					label: 'ค้ำให้กับ',
				},
				loan_topic_name: {
					label: 'ประเภทเงินกู้',
				},
			}
		},
	},
}
</script>